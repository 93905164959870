import {stringReplaceMask, if500, oUrl, uSearch, sDel, sSet, sGet, sName, uLocal, timeShift, searchStrMatchInArray, apiLinkName, productNameConvertToShowPage, dateCheck} from "helper-func"

// api-methods → 1
// Обозначаем ошибку по умолчанию
// есть методы которые не перезапрашиваются, если нужно обновление токена
// список методов
let apiResp500           = {code: 500}
let apiRespDich          = {code: 914, message: 'data format mismatch'}
let apiNoRepeat          = ['auth', 'authByPassword','uploadFile']
let apiUrl               = {
    alert     : 'alert',
    aUrl      : 'auth',
    aPass     : 'authByPassword',
    aLogin    : 'auth/login',
    aLogOut   : 'auth/logout',
    nInit     : 'init',
    bSms      : 'sendSms',
    sSms      : 'sms',
    tMail     : 'sendemail',

    uDataS    : 'users',
    uDataX    : 'info',
    uData     : 'getUsersData',
    uSave     : 'userSave',
    fData     : 'getInfo',

    pStat     : 'static',
    pData     : 'getData',
    pSubs     : 'getSubscriptionData',
    tData     : 'getsubscriptionbyshortlink',
    nProducts : 'products',
    sInfo     : 'subs',
    gKey      : 'getKey',

    bReserve  : 'subscriptionReserve',
    sActivate : 'subscriptionActivate',
    sSuspend  : 'subscriptionSuspend',
    sResume   : 'subscriptionResume',
    sHistory  : 'getSubscriptionOperation',
    sLink     : 'getSubscriptionByShortLink',
    nActivate : 'activate',

    fUpload   : 'uploadFile',
    uHelp     : 'activateVoiceSupport',
}
let fullExtProp          = [1,2,3,4,5,105,106,107,108,114,116,117,118,120,121,122,123,128,129,130,132,133,138,139,140,141,142,143,151,153,154,157,161,162,163,164,165,166,167,168,169,170,171,172,173,174,175,176,177,178,179,180,182,183,184,185,186,187,191,192,193,194,195,196,197,198,199,201,202,203,204,205,206,210,211,212,213,214,215,216,217]

// api-methods → 2
// методы API: ошибки, шортлинк
let apiAlert             = async (code, message = {}, title = '') => {
    message['url'] = (!!document.location.href && document.location.href + '' !== '') ? document.location.href + '' : '-'
    let reqJson = {method: apiUrl.alert, body: {code: code, message: JSON.stringify(message).substring(0, 1000).replace(/\\/g, "")}}
    if (title !== '') {reqJson.body.title = title} else if (code === apiRespDich.code) {reqJson.body.title = apiRespDich.message}
    return await req(reqJson)
}
let urlAlert             = async (typeErr) => {apiAlert(apiRespDich.code, {urlErr: typeErr}).then()}
let apiLinkGuid          = async (guid, pin = 0) => {
    let bodyData = {guid: guid}
    if (pin !== 0) {bodyData['pin'] = pin}
    let outData = apiResp500
    for (let i = 0, ii = 3; i < ii; i++) {
        outData = await req({method: apiUrl.sLink, body: bodyData})
        if (outData.code + '' === '500') {
            await timeShift(5 * 1000)
        } else {
            break
        }
    }
    return outData
}
let apiLinkGuidCheck     = (apiData) => {
    try {
        let outVal = ''
        let outErr = []
        let keyListSkip = false
        let distrIgnore = false

        if (!apiData.data.status) {outErr.push({status: "not found"})} else {
            if (typeof(apiData.data.status) !== "string") {outErr.push({status: "type"})} else {
                if ((['active','suspend','close']).indexOf(apiData.data.status) === -1) {
                    outErr.push({status: apiData.data.status})
                } else if (apiData.data.status !== 'active') {
                    distrIgnore = true
                }
            }
        }

        if (apiData.data.distrList && Object.keys(apiData.data.distrList).length === 1 && apiData.data.distrList[0].key === 'ANDLINK') {
            keyListSkip = true
        }

        if (!apiData.data.productName) {outErr.push({productName: "not found"})} else {
            if (typeof(apiData.data.productName) !== "string") {outErr.push({productName: "type"})} else {
                if (!searchStrMatchInArray(productNameConvertToShowPage(apiData.data.productName), apiLinkName.products)) {outErr.push({productName: apiData.data.productName})}
            }
        }

        if (!apiData.data.isMultiKey) {} else {if (typeof(apiData.data.isMultiKey) !== "boolean") {outErr.push({isMultiKey: "type"})}}

        if (!keyListSkip) {
            if (apiData.data.keyList) {
                if (typeof(apiData.data.keyList) !== "object") {outErr.push({keyList: "type"})} else {
                    (apiData.data.keyList).map((item) => {
                        if (!item.key_id) {outErr.push({keyList_key_id: "not found"})} else {
                            if (typeof(item.key_id) !== "number") {outErr.push({keyList_key_id: "type"})}
                        }

                        if (!item.name) {outErr.push({keyList_name: "not found"})} else {
                            if (typeof(item.name) !== "string") {outErr.push({keyList_name: "type"})} else {
                                if (!searchStrMatchInArray(item.name, ['key','betakey'])) {outErr.push({keyList_name: "value"})}
                            }
                        }

                        if (!item.value) {outErr.push({keyList_value: "not found"})} else {
                            if (typeof(item.value) !== "string") {outErr.push({keyList_value: "type"})}
                        }
                    })
                }
            }
        }

        if (!distrIgnore) {
            if (!apiData.data.distrList) {outErr.push({distrList: "not found"})} else {
                if (typeof(apiData.data.distrList) !== "object") {outErr.push({distrList: "type"})} else {
                    (apiData.data.distrList).map((item) => {
                        if (!item.key_id) {outErr.push({distrList_key_id: "not found"})} else {
                            if (typeof(item.key_id) !== "number") {outErr.push({distrList_key_id: "type"})}
                        }

                        if (!item.key) {outErr.push({distrList_key: "not found"})} else {
                            if (typeof(item.key) !== "string") {outErr.push({distrList_key: "type"})} else {
                                if (!searchStrMatchInArray(item.key, apiLinkName.productsLink)) {outErr.push({distrList_key: item.key})}
                            }
                        }

                        if (!item.name) {outErr.push({distrList_name: "not found"})} else {
                            if (typeof(item.name) !== "string") {outErr.push({distrList_name: "type"})} else {
                                //if (!searchStrMatchInArray(item.name, apiLinkName.products)) {outErr.push({distrList_name: "value"})}
                                if (!searchStrMatchInArray(item.name, apiLinkName.productsForOs) && !searchStrMatchInArray(item.name, apiLinkName.products)) {outErr.push({distrList_name: item.name})}
                            }
                        }

                        if (!item.value) {outErr.push({distrList_value: "not found"})} else {
                            if (typeof(item.value) !== "string") {outErr.push({distrList_value: "type"})} else {
                                try {outVal = new URL(item.value);if (outVal.protocol !== 'https:') {outErr.push({distrList_value: "sert"})}} catch (_) {outErr.push({distrList_value: "value"})}
                            }
                        }
                    })
                }
            }
        }

        if (outErr.length !== 0) {apiAlert(apiRespDich.code, aPack(outErr, '+'), '').then()}
    } catch (e) {
        apiAlert(apiRespDich.code, {error: e}).then()
    }
}
let apiLinkGuidCheckRt   = (apiData) => {
    try {
        let outVal = ''
        let outErr = []
        let coverSplat = false
        let distrIgnore = false
        let keyIgnore = false

        if (!apiData.data.mrfOnlime) {outErr.push({mrfOnlime: "not found"})} else {
            if (typeof(apiData.data.mrfOnlime) !== "number") {outErr.push({mrfOnlime: "type"})}
        }

        if (!apiData.data.cover) {coverSplat = true} else {
            if (typeof(apiData.data.cover) !== "string") {outErr.push({cover: "type"})} else {
                try {outVal = new URL(apiData.data.cover);if (outVal.protocol !== 'https:') {outErr.push({cover: "sert"})}} catch (_) {outErr.push({cover: "value"})}
            }
        }

        if (!apiData.data.instruction) {outErr.push({instruction: "not found"})} else {
            if (typeof(apiData.data.instruction) !== "string") {outErr.push({instruction: "type"})} else {
                try {outVal = new URL(apiData.data.instruction);if (outVal.protocol !== 'https:') {outErr.push({instruction: "sert"})}} catch (_) {outErr.push({instruction: "value"})}
            }
        }

        if (!apiData.data.mrfId) {outErr.push({mrfId: "not found"})} else {
            if (typeof(apiData.data.mrfId) !== "number") {outErr.push({mrfId: "type"})}
        }

        if (!apiData.data.remote_install_price) {outErr.push({remote_install_price: "not found"})} else {
            // string → number
            if (typeof(apiData.data.remote_install_price) !== "string") {outErr.push({remote_install_price: "type"})} else {
                if (apiData.data.remote_install_price !== '400.00') {
                    outErr.push({remote_install_price: "value"})
                }
            }
        }

        if (!apiData.data.inTariff) {} else {if (typeof(apiData.data.inTariff) !== "boolean") {outErr.push({inTariff: "type"})}}
        if (!apiData.data.isTrial) {} else {if (typeof(apiData.data.isTrial) !== "boolean") {outErr.push({isTrial: "type"})}}

        if (!apiData.data.status) {outErr.push({status: "not found"})} else {
            if (typeof(apiData.data.status) !== "string") {outErr.push({status: "type"})} else {
                if ((['active','suspend','close']).indexOf(apiData.data.status) === -1) {
                    outErr.push({status: apiData.data.status})
                } else if (apiData.data.status !== 'active') {
                    distrIgnore = true
                }
            }
        }

        if (!apiData.data.real_price) {outErr.push({real_price: "not found"})} else {
            if (typeof(apiData.data.real_price) !== "number") {outErr.push({real_price: "type"})} else {
                if (apiData.data.real_price < 48 || apiData.data.real_price > 3000) {
                    outErr.push({real_price: apiData.data.real_price})
                }
            }
        }

        if (!apiData.data.productId) {outErr.push({productId: "not found"})} else {
            if (typeof(apiData.data.productId) !== "number") {outErr.push({productId: "type"})}
        }

        if (!apiData.data.duration) {outErr.push({duration: "not found"})} else {
            if (typeof(apiData.data.duration) !== "number") {outErr.push({duration: "type"})}
        }

        if (!apiData.data.productName) {outErr.push({productName: "not found"})} else {
            if (typeof(apiData.data.productName) !== "string") {outErr.push({productName: "type"})} else {
                if (!searchStrMatchInArray(productNameConvertToShowPage(apiData.data.productName), apiLinkName.products)) {outErr.push({productName: apiData.data.productName})}
            }
        }

        if (!apiData.data.distributor_id) {outErr.push({distributor_id: "not found"})} else {
            if (typeof(apiData.data.distributor_id) !== "number") {outErr.push({distributor_id: "type"})} else {
                if (([17,16,349,367,387]).indexOf(apiData.data.distributor_id) === -1) {
                    outErr.push({distributor_id: apiData.data.distributor_id})
                } else if (apiData.data.distributor_id === 16 || apiData.data.distributor_id === 367) {
                    keyIgnore = true
                }
            }
        }

        if (!apiData.data.isMultiKey) {} else {if (typeof(apiData.data.isMultiKey) !== "boolean") {outErr.push({isMultiKey: "type"})}}

        if (!keyIgnore) {
            if (!apiData.data.keyList) {outErr.push({keyList: "not found"})} else {
                if (typeof(apiData.data.keyList) !== "object") {outErr.push({keyList: "type"})} else {
                    (apiData.data.keyList).map((item) => {
                        if (!item.key_id) {outErr.push({keyList_key_id: "not found"})} else {
                            if (typeof(item.key_id) !== "number") {outErr.push({keyList_key_id: "type"})}
                        }

                        if (!item.name) {outErr.push({keyList_name: "not found"})} else {
                            if (typeof(item.name) !== "string") {outErr.push({keyList_name: "type"})} else {
                                if (!searchStrMatchInArray(item.name, ['key','betakey'])) {outErr.push({keyList_name: item.name})}
                            }
                        }

                        if (!item.value) {outErr.push({keyList_value: "not found"})} else {
                            if (typeof(item.value) !== "string") {outErr.push({keyList_value: "type"})}
                        }
                    })
                }
            }
        }

        if (!distrIgnore) {
            if (!apiData.data.distrList) {outErr.push({distrList: "not found"})} else {
                if (typeof(apiData.data.distrList) !== "object") {outErr.push({distrList: "type"})} else {
                    (apiData.data.distrList).map((item) => {
                        if (!item.key_id) {outErr.push({distrList_key_id: "not found"})} else {
                            if (typeof(item.key_id) !== "number") {outErr.push({distrList_key_id: "type"})}
                        }

                        if (!item.key) {outErr.push({distrList_key: "not found"})} else {
                            if (typeof(item.key) !== "string") {outErr.push({distrList_key: "type"})} else {
                                if (!searchStrMatchInArray(item.key, apiLinkName.productsLink)) {outErr.push({distrList_key: item.key})}
                            }
                        }

                        if (!item.name) {outErr.push({distrList_name: "not found"})} else {
                            if (typeof(item.name) !== "string") {outErr.push({distrList_name: "type"})} else {
                                if (!searchStrMatchInArray((item.name).split(' (')[0], apiLinkName.products)) {outErr.push({distrList_name: (item.name).split(' (')[0]})}
                                //if (!searchStrMatchInArray(item.name, apiLinkName.productsForOs)) {outErr.push({distrList_name: item.name})}//RT→no!
                            }
                        }

                        if (!item.value) {outErr.push({distrList_value: "not found"})} else {
                            if (typeof(item.value) !== "string") {outErr.push({distrList_value: "type"})} else {
                                try {outVal = new URL(item.value);if (outVal.protocol !== 'https:' && !isDev()) {outErr.push({distrList_value: "sert"})}} catch (_) {outErr.push({distrList_value: "value"})}
                            }
                        }
                    })
                }
            }
        }

        if (outErr.length !== 0) {
            outErr.push({productId: apiData.data.productId})
            apiAlert(apiRespDich.code, aPack(outErr, '+')).then()
        }
    } catch (e) {
        apiAlert(apiRespDich.code, {error: e}).then()
    }
}
let apiLinkGuidCheckTT   = (apiData) => {
    try {
        let outErr = []
        let outVal = ''

        if (Object.keys(apiData.data).length !== 1) {outErr.push({announce: "object length !"})} else {
            Object.keys(apiData.data).forEach((itemOne, indexOne) => {
                if (!apiData.data[itemOne].announce) {outErr.push({announce: "not found"})} else {
                    if (typeof(apiData.data[itemOne].announce) !== "string") {outErr.push({announce: "type"})} else {
                        if (apiData.data[itemOne].announce === '') {outErr.push({announce: "empty"})}
                    }
                }

                if (!apiData.data[itemOne].cover) {outErr.push({cover: "not found"})} else {
                    if (typeof(apiData.data[itemOne].cover) !== "string") {outErr.push({cover: "type"})} else {
                        try {outVal = new URL(apiData.data[itemOne].cover);if (outVal.protocol !== 'https:') {outErr.push({cover: "sert"})}} catch (_) {outErr.push({cover: "value"})}
                    }
                }

                if (!apiData.data[itemOne].distrList) {outErr.push({distrList: "not found"})} else {
                    if (typeof(apiData.data[itemOne].distrList) !== "object") {outErr.push({distrList: "type"})} else {
                        (apiData.data[itemOne].distrList).map((item) => {
                            if (!item.key_id) {outErr.push({distrList_key_id: "not found"})} else {
                                if (typeof(item.key_id) !== "number") {outErr.push({distrList_key_id: "type"})}
                            }

                            if (!item.key) {outErr.push({distrList_key: "not found"})} else {
                                if (typeof(item.key) !== "string") {outErr.push({distrList_key: "type"})} else {
                                    if (!searchStrMatchInArray(item.key, apiLinkName.productsLink)) {outErr.push({distrList_key: item.key})}
                                }
                            }

                            if (!item.name) {outErr.push({distrList_name: "not found"})} else {
                                if (typeof(item.name) !== "string") {outErr.push({distrList_name: "type"})} else {
                                    if (!searchStrMatchInArray((item.name).split('.')[0].split(' 1')[0], apiLinkName.products)) {outErr.push({distrList_name: (item.name).split(' (')[0]})}
                                    //if (!searchStrMatchInArray(item.name, apiLinkName.productsForOs)) {outErr.push({distrList_name: item.name})}//RT→no!
                                }
                            }

                            if (!item.value) {outErr.push({distrList_value: "not found"})} else {
                                if (typeof(item.value) !== "string") {outErr.push({distrList_value: "type"})} else {
                                    try {outVal = new URL(item.value);if (outVal.protocol !== 'https:') {outErr.push({distrList_value: "sert"})}} catch (_) {outErr.push({distrList_value: "value"})}
                                }
                            }
                        })
                    }
                }

                if (!apiData.data[itemOne].instructions) {outErr.push({instructions: "not found"})} else {
                    if (typeof(apiData.data[itemOne].distrList) !== "object") {outErr.push({instructions: "type"})} else {
                        (apiData.data[itemOne].instructions).map((item) => {
                            if (!item.name) {outErr.push({instructions_name: "not found"})} else {
                                if (typeof(item.name) !== "string") {outErr.push({instructions_name: "type"})} else {
                                    if (!searchStrMatchInArray(item.name, apiLinkName.productsForOs)) {outErr.push({instructions_name: item.name})}
                                }
                            }

                            if (!item.value) {outErr.push({instructions_value: "not found"})} else {
                                if (typeof(item.value) !== "string") {outErr.push({instructions_value: "type"})} else {
                                    try {outVal = new URL(item.value);if (outVal.protocol !== 'https:') {outErr.push({instructions_value: "sert"})}} catch (_) {outErr.push({instructions_value: "value"})}
                                }
                            }
                        })
                    }
                }

                if (!apiData.data[itemOne].isMultiKey) {} else {if (typeof(apiData.data[itemOne].isMultiKey) !== "boolean") {outErr.push({isMultiKey: "type"})}}

                if (!apiData.data[itemOne].keyList) {outErr.push({keyList: "not found"})} else {
                    if (typeof(apiData.data[itemOne].keyList) !== "object") {outErr.push({keyList: "type"})} else {
                        (apiData.data[itemOne].keyList).map((item) => {
                            if (!item.id) {outErr.push({keyList_id: "not found"})} else {
                                if (typeof(item.id) !== "number") {outErr.push({keyList_id: "type"})}
                            }

                            if (!item.name) {outErr.push({keyList_name: "not found"})} else {
                                if (typeof(item.name) !== "string") {outErr.push({keyList_name: "type"})} else {
                                    if (!searchStrMatchInArray(item.name, ['key','betakey'])) {outErr.push({keyList_name: "value"})}
                                }
                            }

                            if (!item.value) {outErr.push({keyList_value: "not found"})} else {
                                if (typeof(item.value) !== "string") {outErr.push({keyList_value: "type"})}
                            }
                        })
                    }
                }

                if (!apiData.data[itemOne].productName) {outErr.push({productName: "not found"})} else {
                    if (typeof(apiData.data[itemOne].productName) !== "string") {outErr.push({productName: "type"})} else {
                        if (!searchStrMatchInArray((apiData.data[itemOne].productName).split('.')[0].split(' (')[0], apiLinkName.products)) {outErr.push({productName: apiData.data[itemOne].productName})}
                    }
                }
            })
        }

        if (outErr.length !== 0) {apiAlert(apiRespDich.code, aPack(outErr, '+')).then()}
    } catch (e) {
        apiAlert(apiRespDich.code, {error: e}).then()
    }
}
let apiLinkGuidCheckCp   = (apiData) => {
    try {
        let outErr = []
        let outVal = ''

        if (!apiData.data) {outErr.push({data: "not found"})} else {
            if (typeof(apiData.data) !== "object") {outErr.push({data: "type"})} else {
                Object.keys(apiData.data).map((item) => {
                    if ((['Windows','MacOS','Android','iOS']).indexOf(item) === -1) {
                        outErr.push({distrName: "value"})
                    } else if (typeof(apiData.data[item]) !== "object") {
                        outErr.push({dataItem: "type"})
                    } else {
                        Object.keys(apiData.data[item]).map((itemLink) => {
                            if ((['kpm','kts','ksk','kis']).indexOf(itemLink) === -1) {
                                outErr.push({distrOs: "value"})
                            } else if (typeof(apiData.data[item][itemLink]) !== "string") {
                                outErr.push({distrOs: "type"})
                            } else {
                                try {outVal = new URL(apiData.data[item][itemLink]);if (outVal.protocol !== 'https:') {outErr.push({distrOs: "sert"})}} catch (_) {outErr.push({distrOs: "value"})}
                            }
                        })
                    }
                })
            }
        }

        if (outErr.length !== 0) {apiAlert(apiRespDich.code, aPack(outErr, '+'), '').then()}
    } catch (e) {
        apiAlert(apiRespDich.code, {error: e}).then()
    }
}
let apiLinkGuidCheckUt   = (apiData) => {
    try {
        let outErr = []
        let outVal = ''

        if (!apiData.data.productName) {outErr.push({productName: "not found"})} else {
            if (typeof(apiData.data.productName) !== "string") {outErr.push({productName: "type"})} else {
                //if (!searchStrMatchInArray(productNameConvertToShowPage(apiData.data.productName), apiLinkName.products)) {outErr.push({productName: apiData.data.productName})}
            }
        }

        if (!apiData.data.distributor_id) {outErr.push({distributor_id: "not found"})} else {
            if (typeof(apiData.data.distributor_id) !== "number") {outErr.push({distributor_id: "type"})} else {
                if (([17,16,349,15]).indexOf(apiData.data.distributor_id) === -1) {
                    outErr.push({distributor_id: apiData.data.distributor_id})
                } else if (apiData.data.distributor_id === 349) {
                    outErr.push({cover: "not found"})
                }
            }
        }

        if (!apiData.data.isMultiKey) {} else {if (typeof(apiData.data.isMultiKey) !== "boolean") {outErr.push({isMultiKey: "type"})}}

        if (!apiData.data.distrList) {outErr.push({distrList: "not found"})} else {
            if (typeof(apiData.data.distrList) !== "object") {outErr.push({distrList: "type"})} else {
                (apiData.data.distrList).map((item) => {
                    if (!item.key_id) {outErr.push({distrList_key_id: "not found"})} else {
                        if (typeof(item.key_id) !== "number") {outErr.push({distrList_key_id: "type"})}
                    }

                    if (!item.key) {outErr.push({distrList_key: "not found"})} else {
                        if (typeof(item.key) !== "string") {outErr.push({distrList_key: "type"})} else {
                            if (!searchStrMatchInArray(item.key, apiLinkName.productsLink)) {outErr.push({distrList_key: item.key})}
                        }
                    }

                    if (!item.name) {outErr.push({distrList_name: "not found"})} else {
                        if (typeof(item.name) !== "string") {outErr.push({distrList_name: "type"})} else {
                            if (!searchStrMatchInArray((item.name).split(' (')[0], apiLinkName.products)) {outErr.push({distrList_name: (item.name).split(' (')[0]})}
                            //if (!searchStrMatchInArray(item.name, apiLinkName.productsForOs)) {outErr.push({distrList_name: item.name})}//RT→no!
                        }
                    }

                    if (!item.value) {outErr.push({distrList_value: "not found"})} else {
                        if (typeof(item.value) !== "string") {outErr.push({distrList_value: "type"})} else {
                            try {outVal = new URL(item.value);if (outVal.protocol !== 'https:') {outErr.push({distrList_value: "sert"})}} catch (_) {outErr.push({distrList_value: "value"})}
                        }
                    }
                })
            }
        }

        if (outErr.length !== 0) {apiAlert(apiRespDich.code, aPack(outErr, '+'), '').then()}
    } catch (e) {
        apiAlert(apiRespDich.code, {error: e}).then()
    }
}
let apiLinkGuidCheckFr   = (apiData) => {
    try {
        let outErr = []
        let outVal = ''

        if (!apiData) {
            outErr.push({data: "not found"})
        } else {
            if (!apiData.subsId) {outErr.push({subsId: "not found"})} else {
                if (typeof(apiData.subsId) !== "number") {outErr.push({subsId: "type"})}
            }

            if (!apiData.productId) {outErr.push({productId: "not found"})} else {
                if (typeof(apiData.productId) !== "number") {outErr.push({productId: "type"})}
            }

            if (!apiData.parentProductId) {outErr.push({parentProductId: "not found"})} else {
                if (typeof(apiData.parentProductId) !== "number") {outErr.push({parentProductId: "type"})}
            }

            if (!apiData.lang) {outErr.push({lang: "not found"})} else {
                if (typeof(apiData.lang) !== "string") {outErr.push({lang: "type"})} else {
                    if ((['de']).indexOf(apiData.lang) === -1) {
                        outErr.push({lang: apiData.lang})
                    }
                }
            }

            if (!apiData.key) {outErr.push({key: "not found"})} else {
                if (typeof(apiData.key) !== "string") {outErr.push({key: "type"})} else {
                    if (apiData.key.length < 5) {
                        outErr.push({key: "short!"})
                    }
                }
            }

            if (!apiData.parentProductName) {outErr.push({parentProductName: "not found"})} else {
                if (typeof(apiData.parentProductName) !== "string") {outErr.push({parentProductName: "type"})} else {
                    if ((apiLinkName.products).indexOf(productNameConvertToShowPage(apiData.parentProductName)) === -1) {
                        outErr.push({parentProductName: apiData.parentProductName})
                    }
                }
            }

            if (!apiData.cover) {outErr.push({cover: "not found"})} else {
                if (typeof(apiData.cover) !== "string") {outErr.push({cover: "type"})} else {
                    try {outVal = new URL(apiData.cover);if (outVal.protocol !== 'https:') {outErr.push({cover: "sert"})}} catch (_) {outErr.push({cover: "value"})}
                }
            }

            if (!apiData.links) {outErr.push({links: "not found"})} else {
                if (typeof(apiData.links) !== "object") {outErr.push({links: "type"})} else {
                    if (Object.keys(apiData.links).length < 1) {outErr.push({links: "length"})} else {
                        Object.keys(apiData.links).forEach(item => {
                            if (apiLinkName.productsLink.indexOf(item) === -1) {outErr.push({links_key: "unknown"})} else {
                                try {outVal = new URL(apiData.links[item]);if (outVal.protocol !== 'https:') {outErr.push({links_url: "sert"})}} catch (_) {outErr.push({links_url: "value"})}
                            }
                        })
                    }
                }
            }

        }

        if (outErr.length !== 0) {apiAlert(apiRespDich.code, aPack(outErr, '+'), '').then()}
    } catch (e) {
        apiAlert(apiRespDich.code, {error: e}).then()
    }
}
let apiLinkGuidCheckMb   = (apiData) => {
    try {
        let outErr = []

        if (!apiData.data) {
            outErr.push({data: "not found"})
        } else {
            if (!apiData.data.keyList) {outErr.push({keyList: "not found"})} else {
                if (typeof(apiData.data.keyList) !== "object") {outErr.push({keyList: "type"})} else {
                    //
                    if (!apiData.data.keyList[0]) {outErr.push({keyList0: "not found"})} else {
                        if (typeof(apiData.data.keyList[0]) !== "object") {outErr.push({keyList0: "type"})} else {
                            //
                            if (!apiData.data.keyList[0].value) {outErr.push({key_value: "not found"})} else {
                                if (typeof(apiData.data.keyList[0].value) !== "string") {outErr.push({key_value: "type"})}
                            }
                            //
                        }
                    }
                    //
                }
            }
        }

        if (outErr.length !== 0) {apiAlert(apiRespDich.code, aPack(outErr, '+'), '').then()}
    } catch (e) {
        apiAlert(apiRespDich.code, {error: e}).then()
    }
}
let apiLinkGuidCheckMg   = (apiData, guid = '') => {
    try {
        let outErr = []
        let outVal = ''
        if (!apiData) {
            outErr.push({data: "not found"})
        } else {
            Object.keys(apiData).forEach(item => {
                if ((['kaspersky','ithelper']).indexOf(item) === -1) {
                    outErr.push({data_keys: "structure-error!"})
                } else {
                    if (!apiData[item].productName) {outErr.push({productName: "not found"})} else {
                        if (typeof(apiData[item].productName) !== "string") {outErr.push({productName: "type"})} else {
                            if ((apiLinkName.products).indexOf(productNameConvertToShowPage(apiData[item].productName)) === -1) {
                                outErr.push({productName: apiData[item].productName})
                            }
                        }
                    }
                    if (!apiData[item].cover) {outErr.push({cover: "not found"})} else {
                        if (typeof(apiData[item].cover) !== "string") {outErr.push({cover: "type"})} else {
                            try {outVal = new URL(apiData[item].cover);if (outVal.protocol !== 'https:') {outErr.push({cover: "sert"})}} catch (_) {outErr.push({cover: "value"})}
                        }
                    }
                    if (!apiData[item].ext_prop) {} else {
                        if (typeof(apiData[item].ext_prop) !== "object") {outErr.push({p_ext_prop: "type"})} else {
                            if (apiData[item].ext_prop.length === 0) {outErr.push({p_ext_prop_length: "length"})} else {
                                apiData[item].ext_prop.map(itemE => {
                                    if ((fullExtProp).indexOf(parseInt(itemE)) === -1) {
                                        // outErr.push({p_ext_prop: "0"})
                                    }
                                })
                            }
                        }
                    }
                    if (!apiData[item].instructions) {outErr.push({instructions: "not found"})} else {
                        if (typeof(apiData[item].instructions) !== "object") {outErr.push({instructions: "type"})} else {
                            if (apiData[item].instructions.length !== 0) {
                                outErr.push({instructions: "length"})
                            }
                        }
                    }
                    if (!apiData[item].keyList) {outErr.push({keyList: "not found"})} else {
                        if (typeof(apiData[item].keyList) !== "object") {outErr.push({keyList: "type"})} else {
                            if (apiData[item].keyList.length === 0) {outErr.push({keyList: "length=0"})} else if (apiData[item].keyList.length > 1) {outErr.push({keyList: "length>1"})} else {
                                if (!apiData[item].keyList[0]) {outErr.push({keyList0: "not found"})} else {
                                    if (!apiData[item].keyList[0].id) {outErr.push({keyList0id: "not found"})} else {
                                        if (typeof(apiData[item].keyList[0].id) !== 'number') {outErr.push({keyList0id: "type"})}
                                    }
                                    if (!apiData[item].keyList[0].name) {outErr.push({keyList0name: "not found"})} else {
                                        if (typeof(apiData[item].keyList[0].name) !== 'string') {outErr.push({keyList0name: "type"})} else {
                                            if (apiData[item].keyList[0].name !== 'key') {
                                                outErr.push({keyList0name: "value"})
                                            }
                                        }
                                    }
                                    if (!apiData[item].keyList[0].value) {outErr.push({keyList0value: "not found"})} else {
                                        if (typeof(apiData[item].keyList[0].value) !== 'string') {outErr.push({keyList0value: "type"})} else {
                                            if (apiData[item].keyList[0].value.length < 14 || apiData[item].keyList[0].value.length > 23) {
                                                outErr.push({keyList0value: "length"})
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (!apiData[item].distrList) {outErr.push({distrList: "not found"})} else {
                        if (typeof(apiData[item].distrList) !== "object") {outErr.push({distrList: "type"})} else {
                            (apiData[item].distrList).map((item2) => {
                                if (!item2.key_id) {outErr.push({distrList_key_id: "not found"})} else {
                                    if (typeof(item2.key_id) !== "number") {outErr.push({distrList_key_id: "type"})}
                                }
                                if (!item2.key) {outErr.push({distrList_key: "not found"})} else {
                                    if (typeof(item2.key) !== "string") {outErr.push({distrList_key: "type"})} else {
                                        if (!searchStrMatchInArray(item2.key, apiLinkName.productsLink)) {outErr.push({distrList_key: item2.key})}
                                    }
                                }
                                if (!item2.name) {outErr.push({distrList_name: "not found"})} else {
                                    if (typeof(item2.name) !== "string") {outErr.push({distrList_name: "type"})} else {
                                        if (!searchStrMatchInArray((item2.name).split(' (')[0], apiLinkName.products)) {outErr.push({distrList_name: (item2.name).split(' (')[0]})}
                                    }
                                }
                                if (!item2.value) {outErr.push({distrList_value: "not found"})} else {
                                    if (typeof(item2.value) !== "string") {outErr.push({distrList_value: "type"})} else {
                                        try {outVal = new URL(item2.value);if (outVal.protocol !== 'https:') {outErr.push({distrList_value: "sert"})}} catch (_) {outErr.push({distrList_value: "value"})}
                                    }
                                }
                            })
                        }
                    }
                    if (!apiData[item].subscription) {} else {
                        if (!apiData[item].subscription.id) {outErr.push({subscription_id: "not found"})} else {
                            if (typeof(apiData[item].subscription.id) !== "number") {outErr.push({subscription_id: "type"})}
                        }
                        if (!apiData[item].subscription.create_date) {outErr.push({create_date: "not found"})} else {
                            if (typeof(apiData[item].subscription.create_date) !== "string") {outErr.push({create_date: "type"})}
                        }
                        if (!apiData[item].subscription.active_from) {outErr.push({active_from: "not found"})} else {
                            if (typeof(apiData[item].subscription.active_from) !== "string") {outErr.push({active_from: "type"})}
                        }
                        if (!apiData[item].subscription.active_to) {} else {
                            if (typeof(apiData[item].subscription.active_to) !== "string") {outErr.push({active_to: "type"})} else {
                                if (apiData[item].subscription.active_to.length !== 19 || parseInt(apiData[item].subscription.active_to.substr(0,4)) < 2024) {
                                    outErr.push({active_to: "value - " + apiData[item].subscription.active_to + ""})
                                }
                            }
                        }
                        if (!apiData[item].subscription.status) {outErr.push({status: "not found"})} else {
                            if (typeof(apiData[item].subscription.status) !== "string") {outErr.push({status: "type"})} else {
                                if ((['Подписана','Отписана']).indexOf(apiData[item].subscription.status) === -1) {
                                    outErr.push({status: apiData[item].subscription.status.toString()})
                                }
                            }
                        }
                    }
                }
            })
        }

        if (outErr.length !== 0) {
            outErr.push({subscriptionId: guid})
            apiAlert(apiRespDich.code, aPack(outErr, '+'), '').then()
        }
    } catch (e) {
        apiAlert(apiRespDich.code, {error: e}).then()
    }
}

// api-methods → 3
// методы: строго для БэкОфиса (идут через обработку bReq, т.к. имеют особенности обработки)
let apiBoSbs             = async (method, ajJson) => {return await req({method: apiUrl[method], body: ajJson})}
let apiBoUserSafe        = async (ajJson) => {return await bReq({method: apiUrl.uSave, body: ajJson})}
let apiBoSubsASR         = async (ajMethod, ajJson) => {return await bReq({method: (ajMethod === 'activate' ? apiUrl.sActivate : (ajMethod === 'suspend' ? apiUrl.sSuspend : apiUrl.sResume)), body: ajJson})}
let apiBoSubsInfo        = async (id) => {return await bReq({method: apiUrl.pData, body: {id: id}})}
let apiBoUserInfo        = async (uuid, id) => {
    let searchParam = {externalId: uSearch(uuid)};
    if (id && uSearch(id) !== '') {searchParam = {id: uSearch(id)}}
    return await bReq({method: apiUrl.uData, body: searchParam})
}
let apiBoHistory         = async (subsId) => {return await bReq({method: apiUrl.sHistory, body: {subscription: subsId}})}
let apiBoAuthFormOrUrl   = async (type = 'form', params = {}) => {
    let url
    if (type === 'form') {
        url = apiUrl.aPass
        params['login_type'] = 'email'
    } else if (type === 'url') {
        url = apiUrl.aUrl
        params = {login: uSearch('login'), uuid: uSearch('uuid'), sign: uSearch('sign')}
    }
    return await req({method: url, body: params})
}
let apiBoHistoryCheck    = async (apiData) => {
    let outErr = []

    if (!apiData.data) {outErr.push({data: "not found"})} else {
        if (typeof(apiData.data) !== "object") {outErr.push({data: "type"})} else {
            if (Object.keys(apiData.data).length > 0) {
                Object.keys(apiData.data).map(item => {

                    if (!apiData.data[item].data) {outErr.push({h_data: "not found"})} else {
                        if (typeof(apiData.data[item].data) !== "string") {outErr.push({h_data: "type"})} else {
                            if (!dateCheck(apiData.data[item].data)) {
                                outErr.push({h_data: apiData.data[item].data})
                            }
                        }
                    }

                    if (!apiData.data[item].source) {outErr.push({u_source: "not found"})} else {
                        if (typeof(apiData.data[item].source) !== "string") {outErr.push({u_source: "type"})} else {

                            let itemArray1 = ['Фрейм','Бекофис','Телемаркетинг','API']
                            let itemMatch1 = false
                            Object.keys(itemArray1).forEach(item2 => {
                                if (itemArray1[item2] === apiData.data[item].source) {
                                    itemMatch1 = true
                                }
                            })
                            if (!itemMatch1) {
                                outErr.push({u_action: apiData.data[item].source})
                            }
                        }
                    }

                    if (!apiData.data[item].action) {outErr.push({u_action: "not found"})} else {
                        if (typeof(apiData.data[item].action) !== "string") {outErr.push({u_action: "type"})} else {

                            let itemArray2 = ['Активация','Остановка','Возобновление','Остановлена по уведомлению с биллинга','Возобновление по уведомлению с биллинга']
                            let itemMatch2 = false
                            Object.keys(itemArray2).forEach(item2 => {
                                if (itemArray2[item2] === apiData.data[item].action) {
                                    itemMatch2 = true
                                }
                            })
                            if (!itemMatch2) {
                                outErr.push({u_action: apiData.data[item].action})
                            }
                        }
                    }

                    if (!apiData.data[item].user) {outErr.push({u_user: "not found"})} else {
                        if (typeof(apiData.data[item].user) !== "string") {outErr.push({u_user: "type"})} else {
                            if (apiData.data[item].user.length === 0) {
                                outErr.push({u_user: apiData.data[item].user})
                            }
                        }
                    }

                    if (!apiData.data[item].error_code) {} else {
                        if (typeof(apiData.data[item].error_code) !== "number") {outErr.push({u_error: "type"})} else {
                            if (([0,-17]).indexOf(apiData.data[item].error_code) === -1) {
                                outErr.push({u_error: apiData.data[item].error_code})
                            }
                        }
                    }

                })
            }
        }
    }

    if (outErr.length !== 0) {apiAlert(apiRespDich.code, aPack(outErr, '+'), '').then()}
}
let apiBoUserInfoCheck   = async (apiData) => {
    let outErr = []

    if (!apiData.data) {outErr.push({data: "not found"})} else {
        if (typeof(apiData.data) !== "object") {outErr.push({data: "type"})} else {
            if (!apiData.data.user) {outErr.push({user: "not found"})} else {
                if (typeof(apiData.data.user) !== "object") {outErr.push({user: "type"})} else {

                    if (!apiData.data.user.id) {outErr.push({u_id: "not found"})} else {
                        if (typeof(apiData.data.user.id) !== "number") {outErr.push({u_id: "type"})}
                    }

                    if (!apiData.data.user.state) {outErr.push({u_state: "not found"})} else {
                        if (typeof(apiData.data.user.state) !== "number") {outErr.push({u_state: "type"})}
                    }

                    if (apiData.data.user.phone) {
                        if (typeof(apiData.data.user.phone) !== "string") {outErr.push({u_phone: "type"})} else {
                            if (apiData.data.user.phone.length !== 11) {outErr.push({u_phone: apiData.data.user.phone})}
                        }
                    }

                    if (!apiData.data.user.externalId) {outErr.push({u_externalId: "not found"})} else {
                        if (typeof(apiData.data.user.externalId) !== "string") {outErr.push({u_externalId: "type"})}
                    }

                    if (apiData.data.user.balance) {
                        if (typeof(apiData.data.user.balance) !== "number") {outErr.push({u_balance: "type"})}
                    }

                }
            }
        }
    }

    if (outErr.length !== 0) {apiAlert(apiRespDich.code, aPack(outErr, '+'), '').then()}
}
let bReq                 = async (params = {}) => {
    let outData = apiResp500
    if (!!params['method'] && params['method'] !== '') {
        if (!params['body'].token) {
            let token = sGet(sName.uBoToken)
            if (token !== '') {params['body'].token = token}
        }
        outData = await req(params)
        if (outData.code === 401) {
            sDel(sName.uFrameToken)
        }
    }
    return outData
}

// api-methods → 4
// методы: строго для Фреймов (идут через обработку fReq, т.к. имеют особенности обработки)
let apiFrameAuthToken    = async (urlParams = []) => {
    let outData = apiResp500
    let tempData = {}
    if (urlParams.length > 0) {
        urlParams.forEach(i => {
            if (uSearch(i)) {tempData[i] = uSearch(i)}})
        outData = await fReq({method: apiUrl.aUrl, body: tempData})
    }
    return outData
}
let apiFrameGetData      = async (isStatic = true, token, authData) => {
    let outData = apiResp500
    for (let i = 0, ii = 3; i < ii; i++) {
        outData = await fReq({method: (isStatic ? apiUrl.pStat : apiUrl.pData), body: {token: token}, auth: authData})
        if (outData.code + '' === '500') {
            await timeShift(5 * 1000)
        } else {
            break
        }
    }
    return outData
}
let apiFrameGetDataSubs  = async (token, subsId) => {
    return await bReq({method: apiUrl.pSubs, body: {subscriptionId: subsId, token: token}})
}
let apiFrameGetDataCheck = async (apiData) => {
    try {
        let outErr = []
        let outVal = ''
        let hitNum = 0
        let ItemShow = 0
        let skipHttps = false

        if (!apiData.data) {outErr.push({data: "not found"})} else {
            if (typeof(apiData.data) !== "object") {outErr.push({data: "type"})} else {
                // in data - start

                if (!apiData.data.products) {outErr.push({data_products: "not found"})} else {
                    if (typeof(apiData.data.products) !== "object") {outErr.push({data_products: "type"})} else {
                        if (Object.keys(apiData.data.products).length === 0) {outErr.push({data_products_length: "length"})} else {
                            Object.keys(apiData.data.products).map((item) => {
                                //apiData.data.products[item]

                                if (!apiData.data.products[item].announce) {outErr.push({p_announce: "not found"})} else {
                                    if (typeof(apiData.data.products[item].announce) !== "string") {outErr.push({p_announce: "type"})} else {
                                        if (apiData.data.products[item].announce.length < 50) {
                                            outErr.push({p_announce: "length"})
                                        }
                                    }
                                }

                                if (apiData.data.products[item].auto_prolong) {
                                    if (typeof(apiData.data.products[item].auto_prolong) !== "number") {outErr.push({p_auto_prolong: "type"})}
                                }

                                if (!apiData.data.products[item].billing_id) {outErr.push({p_billing_id: "not found"})} else {
                                    if (typeof(apiData.data.products[item].billing_id) !== "number") {outErr.push({p_billing_id: "type"})}
                                }

                                if (!apiData.data.products[item].cover) {outErr.push({p_cover: "not found"})} else {
                                    if (typeof(apiData.data.products[item].cover) !== "string") {outErr.push({p_cover: "type"})} else {
                                        try {
                                            outVal = new URL(apiData.data.products[item].cover)
                                            if (outVal.protocol !== 'https:') {
                                                outErr.push({p_cover: "sert"})
                                            }
                                            if ((['png','jpg','jpeg']).indexOf((apiData.data.products[item].cover.split('.').pop()).toLowerCase()) === -1) {
                                                outErr.push({p_cover: "no_image"})
                                            }
                                        } catch (_) {
                                            outErr.push({p_cover: "value"})
                                        }
                                    }
                                }

                                if (!apiData.data.products[item].distributor) {outErr.push({p_distributor_id: "not found"})} else {
                                    if (typeof(apiData.data.products[item].distributor) !== "number") {outErr.push({p_distributor_id: "type"})} else {
                                        if (([15,16,17,349,356,367,386,387,392]).indexOf(apiData.data.products[item].distributor) === -1) {
                                            outErr.push({p_distributor_id: apiData.data.products[item].distributor})
                                        }
                                    }
                                }

                                if (!apiData.data.products[item].duration) {outErr.push({p_duration: "not found"})} else {
                                    if (typeof(apiData.data.products[item].duration) !== "number") {outErr.push({p_duration: "type"})} else {
                                        if (([1,30,365,390,420]).indexOf(apiData.data.products[item].duration) === -1) {
                                            outErr.push({p_duration: apiData.data.products[item].duration})
                                        }
                                    }
                                }

                                if (!apiData.data.products[item].ext_prop) {outErr.push({p_ext_prop: "not found"})} else {
                                    if (typeof(apiData.data.products[item].ext_prop) !== "object") {outErr.push({p_ext_prop: "type"})} else {
                                        if (Object.keys(apiData.data.products[item].ext_prop).length === 0) {outErr.push({p_ext_prop_length: "length"})} else {
                                            Object.keys(apiData.data.products[item].ext_prop).map(itemE => {
                                                if ((fullExtProp).indexOf(parseInt(apiData.data.products[item].ext_prop[itemE])) === -1) {
                                                    outErr.push({p_ext_prop: apiData.data.products[item].ext_prop[itemE]})
                                                }
                                            })
                                        }
                                    }
                                }

                                if (!apiData.data.products[item].id) {outErr.push({p_id: "not found"})} else {
                                    if (typeof(apiData.data.products[item].id) !== "number") {outErr.push({p_id: "type"})}
                                }

                                if (!apiData.data.products[item].instructions) {outErr.push({p_instructions: "not found"})} else {
                                    if (typeof(apiData.data.products[item].instructions) !== "object") {outErr.push({p_instructions: "type"})} else {
                                        if (!apiData.data.products[item].instructions) {outErr.push({p_instructions: "not found"})} else {
                                            Object.keys(apiData.data.products[item].instructions).map(itemI => {
                                                if (apiData.data.products[item].instructions[itemI].name) {
                                                    if (typeof(apiData.data.products[item].instructions[itemI].name) !== "string") {outErr.push({p_instructions_n: "type"})} else {
                                                        if ((["","на Android","на IOS",'на Windows']).indexOf(apiData.data.products[item].instructions[itemI].name) === -1) {
                                                            outErr.push({p_instructions_n: apiData.data.products[item].instructions[itemI].name})
                                                        }
                                                    }
                                                }

                                                if (!apiData.data.products[item].instructions[itemI].value) {outErr.push({p_instructions_v: "not found"})} else {
                                                    if (typeof(apiData.data.products[item].instructions[itemI].value) !== "string") {outErr.push({p_instructions_v: "type"})} else {
                                                        try {outVal = new URL(apiData.data.products[item].instructions[itemI].value);if (outVal.protocol !== 'https:') {outErr.push({p_instructions_v: "sert"})}} catch (_) {outErr.push({p_instructions_v: "value"})}
                                                    }
                                                }
                                            })
                                        }
                                    }
                                }

                                if (apiData.data.products[item].is_hit) {
                                    if (typeof(apiData.data.products[item].is_hit) !== "number") {outErr.push({p_is_hit: "type"})} else {
                                        hitNum++
                                    }
                                }

                                if (apiData.data.products[item].is_show) {
                                    if (typeof(apiData.data.products[item].is_show) !== "number") {outErr.push({p_is_show: "type"})} else {
                                        ItemShow++
                                    }
                                }

                                if (apiData.data.products[item].is_trial_product) {
                                    if (typeof(apiData.data.products[item].is_trial_product) !== "number") {outErr.push({p_is_trial_product: "type"})}
                                }

                                if (apiData.data.products[item].is_year) {
                                    if (typeof(apiData.data.products[item].is_year) !== "number") {outErr.push({p_is_year: "type"})} else {
                                        if (([0,1]).indexOf(apiData.data.products[item].is_year) === -1) {
                                            outErr.push({p_is_year: "value"})
                                        }
                                    }
                                }

                                if (!apiData.data.products[item].license_count) {outErr.push({p_license_count: "not found"})} else {
                                    if (typeof(apiData.data.products[item].license_count) !== "number") {outErr.push({p_license_count: "type"})} else {
                                        if (([1,2,3,5,10]).indexOf(apiData.data.products[item].license_count) === -1) {
                                            outErr.push({p_license_count: apiData.data.products[item].license_count})
                                        }
                                    }
                                }

                                if (!apiData.data.products[item].name) {outErr.push({p_name: "not found"})} else {
                                    if (typeof(apiData.data.products[item].name) !== "string") {outErr.push({p_name: "type"})} else {
                                        if ((apiLinkName.products).indexOf(productNameConvertToShowPage(apiData.data.products[item].name)) === -1) {
                                            outErr.push({p_name: apiData.data.products[item].name})
                                        }
                                    }
                                }

                                if (!apiData.data.products[item].name_group) {outErr.push({p_name_group: "not found"})} else {
                                    if (typeof(apiData.data.products[item].name_group) !== "number") {outErr.push({p_name_group: "type"})} else {
                                        if (([5394,5397]).indexOf(apiData.data.products[item].name_group) === -1) {
                                            outErr.push({p_name_group: apiData.data.products[item].name_group})
                                        }
                                    }
                                }

                                if (!apiData.data.products[item].name_short) {outErr.push({p_name_short: "not found"})} else {
                                    if (typeof(apiData.data.products[item].name_short) !== "string") {outErr.push({p_name_short: "type"})} else {
                                        if ((apiLinkName.products).indexOf(productNameConvertToShowPage(apiData.data.products[item].name_short)) === -1) {
                                            outErr.push({p_name_short: apiData.data.products[item].name_short})
                                        }
                                    }
                                }

                                if (!apiData.data.products[item].ord) {outErr.push({p_ord: "not found"})} else {
                                    if (typeof(apiData.data.products[item].ord) !== "number") {outErr.push({p_ord: "type"})}
                                }

                                if (!apiData.data.products[item].parent) {outErr.push({p_parent: "not found"})} else {
                                    if (typeof(apiData.data.products[item].parent) !== "number") {outErr.push({p_parent: "type"})}
                                }

                                let pricesReal = [
                                    '1.63','1.67','2.20','2.27','2.30','2.40','2.50','2.63','2.97','3.30','3.60','3.63','3.97','4.24','4.30','4.63','4.84','4.97',
                                    '5.00','5.30','5.63','5.96','5.97','6.10','6.30','6.33','6.35','6.63','7.96','7.97','8.96','9.96','9.97','14.96','23.30',

                                    '49.00','50.00','66.00','69.00','72.00','75.00','79.00','89.00','99.00',

                                    '100.00','109.00','119.00','129.00','139.00','149.00','150.00','159.00','169.00','179.00','189.00',
                                    '190.00','199.00','239.00','269.00','299.00','449.00','699.00','890.00','899.00','900.00','990.00',

                                    '1068.00','1188.00','1390.00','1490.00','1559.00','1599.00','1790.00','1800.00','1859.00','1990.00',
                                ]

                                // price → no check, real_price → check
                                if (!apiData.data.products[item].real_price) {outErr.push({p_real_price: "not found"})} else {
                                    if (typeof(apiData.data.products[item].real_price) !== "string") {outErr.push({p_real_price: "type"})} else {
                                        if ((pricesReal).indexOf(apiData.data.products[item].real_price) === -1) {
                                            outErr.push({p_real_price: apiData.data.products[item].real_price})
                                        }
                                    }
                                }

                                if (apiData.data.products[item].trial_days) {
                                    if (typeof(apiData.data.products[item].trial_days) !== "number") {outErr.push({p_trial_days: "type"})} else {
                                        if (([7,14,30,60,90]).indexOf(apiData.data.products[item].trial_days) === -1) {
                                            outErr.push({p_trial_days: apiData.data.products[item].trial_days})
                                        }
                                    }
                                }

                                if (apiData.data.products[item].whole_period_charge) {
                                    if (typeof(apiData.data.products[item].whole_period_charge) !== "number") {outErr.push({p_whole_period_charge: "type"})} else {
                                        if (([0,1]).indexOf(apiData.data.products[item].whole_period_charge) === -1) {
                                            outErr.push({p_whole_period_charge: apiData.data.products[item].whole_period_charge})
                                        }
                                    }
                                }

                            })
                        }
                    }
                }

                if (apiData.data.subscriptions) {
                    if (typeof(apiData.data.subscriptions) !== "object") {outErr.push({data_subscriptions: "type"})} else {
                        if (Object.keys(apiData.data.subscriptions).length > 0) {
                            Object.keys(apiData.data.subscriptions).map((item) => {
                                //apiData.data.subscriptions[item]
                                if (apiData.data.subscriptions[item]) {
                                    if (!apiData.data.subscriptions[item].id) {outErr.push({s_id: "not found"})} else {
                                        if (typeof(apiData.data.subscriptions[item].id) !== "number") {outErr.push({s_id: "type"})}
                                    }

                                    if (!apiData.data.subscriptions[item].subscription_product) {outErr.push({s_subscription_product: "not found"})} else {
                                        if (typeof(apiData.data.subscriptions[item].subscription_product) !== "number") {outErr.push({s_subscription_product: "type"})}
                                    }

                                    if (!apiData.data.subscriptions[item].user_id) {outErr.push({s_user_id: "not found"})} else {
                                        if (typeof(apiData.data.subscriptions[item].user_id) !== "number") {outErr.push({s_user_id: "type"})}
                                    }

                                    if (!apiData.data.subscriptions[item].date_create) {outErr.push({p_date_create: "not found"})} else {
                                        if (typeof(apiData.data.subscriptions[item].date_create) !== "string") {outErr.push({p_date_create: "type"})} else {
                                            if (!dateCheck(apiData.data.subscriptions[item].date_create)) {
                                                outErr.push({s_date_create: apiData.data.subscriptions[item].date_create})
                                            }
                                        }
                                    }

                                    if (apiData.data.subscriptions[item].is_active) {
                                        if (typeof(apiData.data.subscriptions[item].is_active) !== "number") {outErr.push({p_is_active: "type"})} else {
                                            if (([0,1]).indexOf(apiData.data.subscriptions[item].is_active) === -1) {
                                                outErr.push({p_is_active: "value"})
                                            }
                                        }
                                    }

                                    if (!apiData.data.subscriptions[item].status) {outErr.push({s_status: "not found"})} else {
                                        if (typeof(apiData.data.subscriptions[item].status) !== "string") {outErr.push({s_status: "type"})} else {
                                            if ((['active','suspend','close','fin_block']).indexOf(apiData.data.subscriptions[item].status) === -1) {
                                                outErr.push({s_status: apiData.data.subscriptions[item].status})
                                            } else if (apiData.data.subscriptions[item].status !== 'active') {
                                                skipHttps = true
                                            } else if (apiData.data.subscriptions[item].status === 'active') {
                                                if (!apiData.data.subscriptions[item].date_active_to) {outErr.push({p_date_active_to: "not found"})} else {
                                                    if (typeof(apiData.data.subscriptions[item].date_active_to) !== "string") {outErr.push({p_date_active_to: "type"})} else {
                                                        if (!dateCheck(apiData.data.subscriptions[item].date_active_to)) {
                                                            outErr.push({s_date_active_to: apiData.data.subscriptions[item].date_active_to})
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    if (!apiData.data.subscriptions[item].status_name) {outErr.push({s_status_name: "not found"})} else {
                                        if (typeof(apiData.data.subscriptions[item].status_name) !== "string") {outErr.push({s_status_name: "type"})} else {
                                            if ((['Активна','Остановлена','Приостановлена (недостаточно средств)']).indexOf(apiData.data.subscriptions[item].status_name) === -1) {
                                                outErr.push({s_status_name: apiData.data.subscriptions[item].status_name})
                                            }
                                        }
                                    }

                                    if (!apiData.data.subscriptions[item].status_id) {outErr.push({p_status_id: "not found"})} else {
                                        if (typeof(apiData.data.subscriptions[item].status_id) !== "number") {outErr.push({p_status_id: "type"})} else {
                                            if (([4414,4416,4935]).indexOf(parseInt(apiData.data.subscriptions[item].status_id)) === -1) {
                                                outErr.push({p_status_id: apiData.data.subscriptions[item].status_id})
                                            }
                                        }
                                    }

                                    if (apiData.data.subscriptions[item].is_trial) {
                                        if (typeof(apiData.data.subscriptions[item].is_trial) !== "string") {outErr.push({p_is_trial: "type"})} else {
                                            if (!dateCheck(apiData.data.subscriptions[item].is_trial)) {
                                                outErr.push({s_is_trial: "value"})
                                            }
                                        }
                                    }

                                    if (apiData.data.subscriptions[item].trial_expired) {
                                        if (typeof(apiData.data.subscriptions[item].trial_expired) !== "number") {outErr.push({p_trial_expired: "type"})} else {
                                            if (([0,1]).indexOf(parseInt(apiData.data.subscriptions[item].trial_expired)) === -1) {
                                                outErr.push({p_trial_expired: "value"})
                                            }
                                        }
                                    }

                                    if (!apiData.data.subscriptions[item].is_tarif) {outErr.push({s_is_tarif: "not found"})} else {
                                        if (typeof(apiData.data.subscriptions[item].is_tarif) !== "string") {outErr.push({s_is_tarif: "type"})} else {
                                            if ((['0','1']).indexOf(apiData.data.subscriptions[item].is_tarif) === -1) {
                                                outErr.push({s_is_tarif: apiData.data.subscriptions[item].is_tarif})
                                            }
                                        }
                                    }

                                    if (apiData.data.subscriptions[item].disconnect_offer) {
                                        if (typeof(apiData.data.subscriptions[item].disconnect_offer) !== "number") {outErr.push({p_disconnect_offer: "type"})} else {
                                            if (([0,1]).indexOf(parseInt(apiData.data.subscriptions[item].disconnect_offer)) === -1) {
                                                outErr.push({p_disconnect_offer: "value"})
                                            }
                                        }
                                    }

                                    if (!apiData.data.subscriptions[item].shipmentData) {outErr.push({s_shipmentData: "not found"})} else {
                                        if (typeof(apiData.data.subscriptions[item].shipmentData) !== "object") {outErr.push({s_shipmentData: "type"})} else {

                                            //key
                                            if (apiData.data.subscriptions[item].shipmentData.key) {
                                                if (typeof(apiData.data.subscriptions[item].shipmentData.key) !== "object") {outErr.push({s_shipmentData_k: "type"})} else {
                                                    if (apiData.data.subscriptions[item].shipmentData.key.key_id) {
                                                        if (typeof(apiData.data.subscriptions[item].shipmentData.key.key_id) !== "string") {outErr.push({s_shipmentData_k_id: "type"})}
                                                    }

                                                    if (apiData.data.subscriptions[item].shipmentData.key.name) {
                                                        if (typeof(apiData.data.subscriptions[item].shipmentData.key.name) !== "string") {outErr.push({s_shipmentData_k_name: "type"})} else {
                                                            if ((['key']).indexOf(apiData.data.subscriptions[item].shipmentData.key.name) === -1) {
                                                                outErr.push({s_shipmentData_k_name: apiData.data.subscriptions[item].shipmentData.key.name})
                                                            }
                                                        }
                                                    }

                                                    if (apiData.data.subscriptions[item].shipmentData.key.value) {
                                                        if (typeof(apiData.data.subscriptions[item].shipmentData.key.value) !== "string") {outErr.push({s_shipmentData_k_value: "type"})}
                                                    }
                                                }
                                            }

                                            //distributive
                                            if (!apiData.data.subscriptions[item].shipmentData.distributive) {outErr.push({s_shipmentData_d: "not found"})} else {
                                                if (typeof(apiData.data.subscriptions[item].shipmentData.distributive) !== "object") {outErr.push({s_shipmentData_d: "type"})} else {
                                                    if (Object.keys(apiData.data.subscriptions[item].shipmentData.distributive).length > 0) {
                                                        Object.keys(apiData.data.subscriptions[item].shipmentData.distributive).map(itemD => {

                                                            if (!apiData.data.subscriptions[item].shipmentData.distributive[itemD].name) {outErr.push({s_shipmentData_d_name: "not found"})} else {
                                                                if (typeof(apiData.data.subscriptions[item].shipmentData.distributive[itemD].name) !== "string") {outErr.push({s_shipmentData_d_name: "type"})} else {
                                                                    if ((['Дистрибутив','hyperlink','hyperlink_macosx','hyperlink_linux','betakey']).indexOf(apiData.data.subscriptions[item].shipmentData.distributive[itemD].name) === -1) {
                                                                        outErr.push({s_shipmentData_d_name: apiData.data.subscriptions[item].shipmentData.distributive[itemD].name})
                                                                    }
                                                                }
                                                            }

                                                            if (!skipHttps) {
                                                                if (!apiData.data.subscriptions[item].shipmentData.distributive[itemD].value) {outErr.push({s_shipmentData_d_value: "not found"})} else {
                                                                    if (typeof(apiData.data.subscriptions[item].shipmentData.distributive[itemD].value) !== "string") {outErr.push({s_shipmentData_d_value: "type"})} else {
                                                                        try {outVal = new URL(apiData.data.subscriptions[item].shipmentData.distributive[itemD].value);if (outVal.protocol !== 'https:' && !isDev()) {outErr.push({s_shipmentData_d_value: "sert"})}} catch (_) {outErr.push({s_shipmentData_d_value: "value"})}
                                                                    }
                                                                }
                                                            }

                                                            if (!apiData.data.subscriptions[item].shipmentData.distributive[itemD].title) {outErr.push({s_shipmentData_d_title: "not found"})} else {
                                                                if (typeof(apiData.data.subscriptions[item].shipmentData.distributive[itemD].title) !== "string") {outErr.push({s_shipmentData_d_title: "type"})} else {
                                                                    if ((apiLinkName.products).indexOf(apiData.data.subscriptions[item].shipmentData.distributive[itemD].title) === -1) {
                                                                        outErr.push({s_shipmentData_d_title: apiData.data.subscriptions[item].shipmentData.distributive[itemD].title})
                                                                    }
                                                                }
                                                            }

                                                            if (!apiData.data.subscriptions[item].shipmentData.distributive[itemD].type) {outErr.push({s_shipmentData_d_type: "not found"})} else {
                                                                if (typeof(apiData.data.subscriptions[item].shipmentData.distributive[itemD].type) !== "number") {outErr.push({s_shipmentData_d_type: "type"})} else {
                                                                    if (([5813,5814,5815,5817,5818,5819,5820,5821]).indexOf(apiData.data.subscriptions[item].shipmentData.distributive[itemD].type) === -1) {
                                                                        outErr.push({s_shipmentData_d_type: apiData.data.subscriptions[item].shipmentData.distributive[itemD].type})
                                                                    }
                                                                }
                                                            }
                                                        })
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    if (!apiData.data.subscriptions[item].product) {outErr.push({s_product: "not found"})} else {
                                        if (typeof(apiData.data.subscriptions[item].product) !== "object") {outErr.push({s_product: "type"})} else {
                                            //product → skip!
                                        }
                                    }
                                }
                            })
                        }
                    }
                }

                // in data - end

                if (hitNum === 0 || hitNum > 3) {
                    outErr.push({hit_number: hitNum + "!"})
                }
            }
        }

        if (outErr.length !== 0) {apiAlert(apiRespDich.code, aPack(outErr, '+'), '').then()}
    } catch (e) {
        apiAlert(apiRespDich.code, {error: e}).then()
    }
}
let apiFrameGetDataSubsCheck = async (apiData) => {
    try {
        let outErr = []
        let outVal = ''

        if (!apiData.data) {outErr.push({data: "not found"})} else {
            if (typeof(apiData.data) !== "object") {outErr.push({data: "type"})} else {
                // in data - start
                if (!apiData.data['subscription'].shipmentData) {outErr.push({s_shipmentData: "not found"})} else {
                    if (typeof(apiData.data['subscription'].shipmentData) !== "object") {outErr.push({s_shipmentData: "type"})} else {

                        //key
                        if (apiData.data['subscription'].shipmentData.key) {
                            if (typeof(apiData.data['subscription'].shipmentData.key) !== "object") {outErr.push({s_shipmentData_k: "type"})} else {
                                if (apiData.data['subscription'].shipmentData.key.key_id) {
                                    if (typeof(apiData.data['subscription'].shipmentData.key.key_id) !== "string") {outErr.push({s_shipmentData_k_id: "type"})}
                                }

                                if (apiData.data['subscription'].shipmentData.key.name) {
                                    if (typeof(apiData.data['subscription'].shipmentData.key.name) !== "string") {outErr.push({s_shipmentData_k_name: "type"})} else {
                                        if ((['key']).indexOf(apiData.data['subscription'].shipmentData.key.name) === -1) {
                                            outErr.push({s_shipmentData_k_name: apiData.data['subscription'].shipmentData.key.name})
                                        }
                                    }
                                }

                                if (apiData.data['subscription'].shipmentData.key.value) {
                                    if (typeof(apiData.data['subscription'].shipmentData.key.value) !== "string") {outErr.push({s_shipmentData_k_value: "type"})}
                                }
                            }
                        }

                        //distributive
                        if (!apiData.data['subscription'].shipmentData.distributive) {outErr.push({s_shipmentData_d: "not found"})} else {
                            if (typeof(apiData.data['subscription'].shipmentData.distributive) !== "object") {outErr.push({s_shipmentData_d: "type"})} else {
                                if (Object.keys(apiData.data['subscription'].shipmentData.distributive).length > 0) {
                                    Object.keys(apiData.data['subscription'].shipmentData.distributive).map(itemD => {

                                        if (!apiData.data['subscription'].shipmentData.distributive[itemD].name) {outErr.push({s_shipmentData_d_name: "not found"})} else {
                                            if (typeof(apiData.data['subscription'].shipmentData.distributive[itemD].name) !== "string") {outErr.push({s_shipmentData_d_name: "type"})} else {
                                                if ((['Дистрибутив','hyperlink','hyperlink_macosx','hyperlink_linux','betakey']).indexOf(apiData.data['subscription'].shipmentData.distributive[itemD].name) === -1) {
                                                    outErr.push({s_shipmentData_d_name: apiData.data['subscription'].shipmentData.distributive[itemD].name})
                                                }
                                            }
                                        }

                                        if (!apiData.data['subscription'].shipmentData.distributive[itemD].value) {outErr.push({s_shipmentData_d_value: "not found"})} else {
                                            if (typeof(apiData.data['subscription'].shipmentData.distributive[itemD].value) !== "string") {outErr.push({s_shipmentData_d_value: "type"})} else {
                                                try {outVal = new URL(apiData.data['subscription'].shipmentData.distributive[itemD].value);if (outVal.protocol !== 'https:' && !isDev()) {outErr.push({s_shipmentData_d_value: "sert"})}} catch (_) {outErr.push({s_shipmentData_d_value: "value"})}
                                            }
                                        }

                                        if (!apiData.data['subscription'].shipmentData.distributive[itemD].title) {outErr.push({s_shipmentData_d_title: "not found"})} else {
                                            if (typeof(apiData.data['subscription'].shipmentData.distributive[itemD].title) !== "string") {outErr.push({s_shipmentData_d_title: "type"})} else {
                                                if ((apiLinkName.products).indexOf(apiData.data['subscription'].shipmentData.distributive[itemD].title) === -1) {
                                                    outErr.push({s_shipmentData_d_title: apiData.data['subscription'].shipmentData.distributive[itemD].title})
                                                }
                                            }
                                        }

                                        if (!apiData.data['subscription'].shipmentData.distributive[itemD].type) {outErr.push({s_shipmentData_d_type: "not found"})} else {
                                            if (typeof(apiData.data['subscription'].shipmentData.distributive[itemD].type) !== "number") {outErr.push({s_shipmentData_d_type: "type"})} else {
                                                if (([5813,5814,5815,5817,5818,5819,5820,5821]).indexOf(apiData.data['subscription'].shipmentData.distributive[itemD].type) === -1) {
                                                    outErr.push({s_shipmentData_d_type: apiData.data['subscription'].shipmentData.distributive[itemD].type})
                                                }
                                            }
                                        }
                                    })
                                }
                            }
                        }
                    }
                }
                // in data - end
            }
        }

        if (outErr.length !== 0) {apiAlert(apiRespDich.code, aPack(outErr, '+'), '').then()}
    } catch (e) {
        apiAlert(apiRespDich.code, {error: e}).then()
    }
}
let apiFrameUserSafe     = async (ajJson) => {return await fReq({method: apiUrl.uSave, body: ajJson})}
let apiFrameSubsASR      = async (ajMethod, ajJson) => {return await fReq({method: (ajMethod === 'activate' ? apiUrl.sActivate : (ajMethod === 'suspend' ? apiUrl.sSuspend : apiUrl.sResume)), body: ajJson})}
let fReq                 = async (params = {}) => {
    let outData = apiResp500
    if (!!params['method'] && params['method'] !== '') {
        outData = await req(params)
        if (outData.status === 401 && apiNoRepeat.indexOf(params['method']) === -1 && Object.keys(params['auth']).length > 2) {
            sDel(sName.uFrameToken)
            await req({method: apiUrl.aUrl, params: uPack(params['auth'])}).then(r => {
                if (r.status === 200) {
                    let tempToken = ''
                    tempToken[uSearch('uuid')] = r.token
                    sSet(sName.uFrameToken, JSON.stringify(tempToken))
                    outData = req(params)
                }
            })
        }
    }
    return outData
}

let bPack                = () => {return {client: {userAgent: navigator.userAgent},pSizes: {'s1-window': window.screen.width + 'x' + window.screen.height, 's2-avail': window.screen.availWidth + 'x' + window.screen.availHeight, 's3-outer': window.outerWidth + 'x' + window.outerHeight, 's4-inner': window.innerWidth + 'x' + window.innerHeight, 's5-client': document.documentElement.clientWidth + 'x' + document.documentElement.clientHeight, 's6-scroll': document.documentElement.scrollWidth + 'x' + document.documentElement.scrollHeight, 's7-visualViewport': window.visualViewport.width + 'x' + window.visualViewport.height,}}}
let uPack                = (param, searchMode = true) => {
    let outData = ''
    if (Object.keys(param).length > 0) {Object.keys(param).forEach(item => {if (outData !== '') {outData += '&';}if (searchMode) {outData += param[item] + '=' + uSearch(param[item])} else {outData += item + '=' + param[item]}})}
    return outData
}
let aPack                = (sendParams, serverAnswer) => {return {ver: 54625, sendParams: sendParams, serverAnswer: serverAnswer}}
let isDev                = () => {return (document.location.href.split('demo').length === 2 || document.location.href.split('localhost').length === 2)}

// api-methods → 5
// обработка запросов POST/GET
let req                  = async (params = {}) => {
    let outData = apiResp500
    let timeStart = Date.now()
    try {
        let reqUrl = stringReplaceMask(oUrl(), (params['apiRule'] === 'bang' ? 'xhrB' : 'xhr')) + '/' + (params['apiRule'] === 'bang' ? 'partners' : 'api') + '/' + (document.location.host.split('.')[0] === 'support' ? 'support' : 'frontend') + '/' + params['method']
        let reqOpt = !!params['options'] ? params['options'] : {method: 'POST', headers: {'Content-Type': 'application/json'}}
        if (params['rType'] === 'GET') {reqUrl += '?' + uPack(params['gets'], params['noSearch']);reqOpt = {method: params['rType']}} else if (params['form'] !== 'form') {reqOpt['body'] = JSON.stringify(params['body'])}
        let eqVirtualErr = false
        if (!uLocal() || eqVirtualErr) {
            await fetch(reqUrl, reqOpt)
                .then((r) => {
                    if (!eqVirtualErr && params['method'] !== apiUrl.alert) {
                        if (if500(r.status) && !params['deep']) {
                            params['deep'] = 1
                            params['time'] = Date.now() - timeStart
                            apiAlert(r.status, aPack(params, r), '')
                        } else if (r.status === 401) {
                            return {code: r.status}
                        } else {
                            return r.json()
                        }
                    } else {
                        return apiRespDich
                    }
                })
                .then((d) => {
                    // 0 - success, -2 - wrong param/guid, -3 - wrong login/pass, -8 - low balance, -10 subscription not found, -17 no money, ERROR_SUBSCRIPTIONS_NOT_FOUND, -22 - wrong pin
                    if (([0,-2,-3,-8,-10,-17,-19,-22,401,500]).indexOf(d.code) === -1 && params['method'] !== apiUrl.alert) {
                        if ((d.code === 255 && d.message.toString().substring(0, 3) === '-17')) { } else {
                            params['time'] = Date.now() - timeStart
                            //params['client'] = bPack()
                            apiAlert(d.code, aPack(params, d), '')
                        }
                    }
                    outData = d
                })
                .catch((err_fetch) => {
                    outData = {block: 'fetch', reqParam: params, error: err_fetch}
                })
        } else {
            // console.log(params['method'])
            // console.log(params)
            // console.log(reqOpt)
            // if (params['method'] === 'method') {outData = {code: 0}}
        }
    } catch (err_catch) {outData = {block: 'catch', reqParam: params, error: err_catch}}
    return outData
}

export {
    apiUrl, apiAlert, apiLinkGuid, apiLinkGuidCheck, apiLinkGuidCheckRt, apiLinkGuidCheckTT, apiLinkGuidCheckCp, apiFrameGetDataSubsCheck,
    apiBoAuthFormOrUrl, apiBoHistory, apiBoUserInfo, apiBoSubsInfo, apiBoUserSafe, apiBoSubsASR, apiBoSbs, apiFrameGetDataSubs,
    apiFrameAuthToken, apiFrameGetData, apiFrameUserSafe, apiFrameSubsASR, apiLinkGuidCheckUt, apiFrameGetDataCheck,
    req, apiBoUserInfoCheck, apiBoHistoryCheck, apiLinkGuidCheckFr, apiLinkGuidCheckMb, apiLinkGuidCheckMg, urlAlert,
}
